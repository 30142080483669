.suggestions-container {
    position:relative;
}

.suggestions-list {
    position:absolute;
    margin-top:-5px;
    margin-left:10px;
    z-index: 1000;
    width:300px;
    border:1px solid #ccc;
    background:#fff;
    color:#777;
}

.suggestions-element:not(:last-child) {
    border-bottom:1px solid #eee;
}

.suggestions-element {
    padding:5px;
    cursor: pointer;
}

.suggestions-element:hover {
    background:#eee;
    color:#333;
}

