.faktura-tabela-kwota {
    font-variant-numeric: tabular-nums;
    color: #555;
}


.faktura-tabela-opis {
    color: #aaa;
}

.faktura-tabela-kwota,
.faktura-tabela-kwota-h {
    display: flex;
    justify-content: flex-end;
}



.faktura-paid {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.faktura-paid-text {
    font-size: 11px;
    color:#bbb;
}

.faktura-paid-icon {
    font-size: 30px;
    line-height: 30px;
    width:66px;
    text-align: center;
    color: #5ba55a;
}

.faktura-notpaid-icon {
    width:66px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: #bbb;
}

.faktura-tabela-paid,
.faktura-tabela-paid-h {
    justify-content: center;
}

.faktura-duedate-today {
    color:#f0af20;
}

.faktura-duedate-before {
    color:#777;
}

.faktura-duedate-after {
    color:#db4744;
}


.faktury-container {
    display: flex;
    width:100%;
    flex-direction: column;
}

.faktury-table-container {
    flex:1;
    position: relative;
}

.faktury-bottom-container {
    padding: 20px 0px;
}

.add-faktura-button {
    /*background:#fff;*/
}

.faktury-table {
    position: absolute;
    left:0px;
    top:0;
    right:0px;
    bottom:0;
    display: flex;
    flex-direction: column;
}

.faktury-table-body {
    flex:1;
    overflow-y: scroll;
}

.faktury-table-row {
    display: flex;
    align-items: center;
}


.faktury-table-row>div:nth-child(1) {
    width:100px;
}

.faktury-table-row>div:nth-child(2) {
    width:400px;
}

.faktury-table-row>div:nth-child(3) {
    width:150px;
}
.faktury-table-row>div:nth-child(4) {
    width:100px;
}
.faktury-table-row>div:nth-child(5) {
    width:300px;
}

.faktury-table-body .faktury-table-row:nth-child(even)>div {
    background:#f6f6f6;
}

.faktury-table-body .faktury-table-row:hover>div {
    background:#eee;
}


.faktury-table-row>div {
    border-left:1px solid #ccc;
    border-top:1px solid #ccc;
    height:44px;
    display: flex;
    align-items: center;
    padding:0px 4px;
}

.faktury-table-row>div:last-child {
    border-right:1px solid #ccc;
}

.faktury-table-row:last-of-type>div {
    border-bottom:1px solid #ccc;
}

.faktury-table-header>div {
    border-bottom:1px solid #ccc;
}

.faktury-table-header {
    font-weight: bold;
}

.faktury-table-header>div {
    background: #2aa5eb;
    color:#fff;
}

.faktury-table-faktura {
    justify-content: space-between;
    cursor: pointer;
}

.faktury-table-faktura:hover {
    background: #ecf7fd !important;
}

.faktury-table-number {
    font-size: 12px;
    color:#777;
}


.faktury-nav {
    display:flex;
}


.faktury-bar {
    display: flex;
    align-items: center;
    max-width: 1050px;
}

.faktura-filt-active {
    background-color: beige;
    font-weight: bolder;
}

.faktury-suma-currency {
    color: #aaa;
}