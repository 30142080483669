.btn:focus,.form-select:focus {
    box-shadow: none;
}

.view-faktura {
    color:dodgerblue;
    cursor:pointer;
}

.view-kontrachent {
    cursor:pointer !important;
    margin-left:5px;
    color:dodgerblue;
}

.faktura-delete-button {
    display: flex;
    justify-content: center;
    opacity: 0.5;
    cursor: pointer;
    color:#db4744;
}

.faktura-delete-button:hover {
    opacity: 1;
}


#upload_faktura {

}

#faktura-view {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.5);
}

#faktura-view > iframe {
    position: absolute;
    background: #fff;
    left:100px;
    top:20px;
    width:calc(100% - 550px);
    height: calc(100% - 40px);
}

#faktura-side {
    position: absolute;
    padding-top: 20px;
    padding-bottom: 20px;
    top:20px;
    right:100px;
    width:346px;
    height: calc(100% - 40px);
    background: #fff;
}

.drag-dest {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(30,100,220,0.1);
    border:2px solid #1a73e8;
    pointer-events: none;
}

.drag-dest > div {
    margin-bottom: 10%;
    background: #308ef2;
    border-radius: 2px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    color:#fff;
    font-size: 14px;
    padding: 5px 20px;
}


.login {
    margin-top:100px;
    width:300px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding:20px 20px;
}

.login-container {
    position:absolute;
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-menu .dropdown-menu {
    right:0;
    left:auto;
}

.faktura-buttons i {
    color: #1a73e8;
}

.faktury-back-forward {
    color:#777;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.faktury-back-forward:hover {
    color:#aaa;
}

.faktury-back-forward i {
    font-size:20px;
}

.main-container {
    position:absolute;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
}


.main {
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    display: flex;
    align-items: stretch;
}


.main-content {
    flex:1;
    background:#fff;
    padding-top:20px;
    padding-left: 20px;
    display:flex;
    align-items: stretch;
}

.main-sidebar {
    width:300px;
    color:#555;
    background:#ccc;
    padding:10px;
}

.main-sidebar-info {
    padding-top:20px;
}

.main-sidebar-menu {
    margin-top:20px;
}

.main-sidebar-menu >div {
    padding:5px 10px;
    cursor:pointer;
}
.main-sidebar-menu>div:last-child {
    margin-top:50px;
}

.main-sidebar-menu .active {
    color:#333;
    font-weight: 600;
}

.main-sidebar-menu>div:not(.active):hover {
    color:#777;
}

.main-sidebar-logo {
    height:30px;
    margin-left:10px;
    margin-top:10px;
}

.main-toolbar {
    background:#ccc;
}